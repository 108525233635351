<template>
  <main
    class="flex flex-col justify-center items-center px-6 py-40 w-full leading-tight text-center whitespace-nowrap max-md:px-5 max-md:py-24 max-md:max-w-full"
    style="background-image: url('/logo.png'); background-size: cover; background-position: center;"
  >
      <h1 class="text-[96px] font-bold text-[color:var(--sds-color-text-default-default)] max-md:text-[48px]">
        Quantify
      </h1>
      <h1 class="mt-2 text-[96px] font-semibold text-[color:var(--sds-color-text-default-secondary)] max-md:text-[24px]">
        Jiujitsu
      </h1>
  </main>
</template>
